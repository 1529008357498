<template>
  <profile-layout class="recommendations">
    <h3 class="recommendations__title">Follow these tips to improve your lung health</h3>

    <card class="recommendations__protect-tip">
      <div class="recommendations__headline-wrapper">
        <div class="recommendations__number">1</div>
        <h3 class="recommendations__headline">Protect Your Lungs</h3>
        <img
          src="@/assets/images/recommendations-protect-tip.svg"
          alt="close"
          class="recommendations__headline-icon"
        />
      </div>
      <div class="recommendations__body">
        <ul>
          <li>If you smoke or vape, stop.</li>
          <li>Avoid secondhand smoke.</li>
          <li>Use air filters to reduce indoor pollutants.</li>
          <li>
            Minimize time outside on bad air days.
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.airnow.gov/"
            >Check the air quality (US only)</a>
          </li>
          <li>
            Prevent respiratory infections by washing your hands regularly and
            getting vaccinated against the flu every year.
          </li>
          <li>Stay hydrated.</li>
        </ul>
      </div>
    </card>

    <card class="recommendations__breathing-tip">
      <div class="recommendations__headline-wrapper">
        <div class="recommendations__number">2</div>
        <div>
          <h3 class="recommendations__headline">Breathing Exercises</h3>
          <p
            class="recommendations__subheadline"
          >Strengthen and train your diaphragm to breathe more effectively</p>
        </div>
        <img
          src="@/assets/images/recommendations-breathing-tip.svg"
          alt="close"
          class="recommendations__headline-icon"
        />
      </div>
      <div class="recommendations__body">
        <h4>Belly Breathing</h4>
        <div class="recommendations__video">
          <youtube url="https://www.youtube.com/watch?v=_xQJ2O4b5TM" />
        </div>
        <h4>Pursed-lips Breathing</h4>
        <div class="recommendations__video">
          <youtube url="https://www.youtube.com/watch?v=7nhHHoc8hCY" />
        </div>
      </div>
    </card>

    <card class="recommendations__exercise-tip">
      <div class="recommendations__headline-wrapper">
        <div class="recommendations__number">3</div>
        <div>
          <h3 class="recommendations__headline">General Exercise</h3>
          <p
            class="recommendations__subheadline"
          >Regular exercise contributes to improved lung health</p>
        </div>
        <img
          src="@/assets/images/recommendations-exercise-tip.svg"
          alt="close"
          class="recommendations__headline-icon"
        />
      </div>
      <div class="recommendations__body">
        <ul>
          <li>
            Learn more from the American Lung Association about:
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.lung.org/lung-health-diseases/wellness/exercise-and-lung-health"
            >How exercise can strengthen your lungs</a>
          </li>
          <li>
            Remember:
            <ul>
              <li>
                Consult a doctor before modifying or starting a new exercise routine especially
                if you have an underlying health condition.
              </li>
              <li>Take extra care to avoid air pollution when exercising.</li>
            </ul>
          </li>
        </ul>
      </div>
    </card>
  </profile-layout>
</template>

<script>
import ProfileLayout from "@/components/profile/profileLayout";
import Card from "@/components/card";
import Youtube from "@/components/youtube";
import logVisiting from "@/mixins/logVisiting";

export default {
  name: "recommendationsPage",

  components: {
    ProfileLayout,
    Card,
    Youtube
  },

  mixins: [logVisiting],

  data: () => ({
    bellyBreathingVideo: {
      url: "https://vimeo.com/423879558/d0c74b7695",
      id: "423879558"
    },
    pursedLipsBreathingVideo: {
      url: "https://vimeo.com/423879558/d0c74b7695",
      id: "423879558"
    }
  })
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";

.recommendations {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.recommendations__title {
  color: $color-primary-light;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  padding-bottom: 0.5rem;
}

.recommendations__headline-wrapper {
  display: flex;
  align-items: center;
  min-height: 80px;
  padding: 0 80px 0 24px;
  position: relative;
}

.recommendations__protect-tip .recommendations__headline-wrapper {
  background-color: $color-secondary-dark;
}

.recommendations__breathing-tip .recommendations__headline-wrapper {
  background-color: #fadb5f;
}

.recommendations__exercise-tip .recommendations__headline-wrapper {
  background-color: $color-primary-lightest;
}

.recommendations__headline {
  margin: 0;
}

.recommendations__protect-tip .recommendations__headline {
  color: $color-white;
  line-height: 2rem;
}

.recommendations__breathing-tip .recommendations__headline {
  color: #212121;
  line-height: 1.25rem;
  margin-top: 4px;
}

.recommendations__exercise-tip .recommendations__headline {
  color: $color-white;
  line-height: 1.25rem;
  margin-top: 4px;
}

.recommendations__number {
  background-color: $color-white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 6px 0 6px;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin-right: 16px;
  flex-shrink: 0;
}

.recommendations__protect-tip .recommendations__number {
  color: #014d40;
}

.recommendations__breathing-tip .recommendations__number {
  color: #8d2b0b;
}

.recommendations__exercise-tip .recommendations__number {
  color: #002159;
}

.recommendations__subheadline {
  font-size: 0.875rem;
}

.recommendations__breathing-tip .recommendations__subheadline {
  color: #212121;
}

.recommendations__exercise-tip .recommendations__subheadline {
  color: $color-white;
}

.recommendations__body {
  padding: 0.5rem;
}

.recommendations__body a {
  color: $color-secondary-dark;
  text-decoration: underline;
}

.recommendations__body > ul {
  list-style: disc;
  margin-left: 1rem;
}

.recommendations__body ul li {
  font-size: 1rem;
  line-height: 1.25rem;
  color: $color-black;
  margin: 16px;
}

.recommendations__body ul li ul {
  list-style: circle;
}

.recommendations__body h4 {
  color: #212121;
  text-align: center;
  margin-top: 30px;
}

.recommendations__video {
  display: flex;
  justify-content: center;
  padding: 1rem 0 0.5rem;
}

.recommendations__headline-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.recommendations__protect-tip .recommendations__headline-icon {
  top: -5px;
  right: -6px;
  width: 90px;
  height: 81px;
}

.recommendations__breathing-tip .recommendations__headline-icon {
  top: -8px;
  right: 6px;
  width: 80px;
  height: 88px;
}

.recommendations__exercise-tip .recommendations__headline-icon {
  top: 8px;
  right: -10px;
  width: 99px;
  height: 73px;
}
</style>
