<template>
  <div v-if="id" class="youtube" :class="{ disabled }">
    <youtube
      class="youtube__player"
      :video-id="id"
      ref="youtube"
      @playing="onPlay"
      @paused="onPause"
    />
  </div>
</template>

<script>
import { Youtube, getIdFromUrl } from "vue-youtube";
import { saveLog } from "@/modules/logger";

export default {
  components: { Youtube },

  props: {
    disabled: { type: Boolean, default: false },
    url: { type: String, required: true }
  },

  computed: {
    id() {
      return getIdFromUrl(this.url);
    }
  },

  watch: {
    disabled(value) {
      if (!value) return;

      this.pause();
    }
  },

  methods: {
    pause() {
      this.$refs.youtube.pauseVideo();
    },

    onPlay() {
      saveLog("video-play");
    },

    onPause() {
      saveLog("video-pause");
    }
  }
};
</script>

<style lang="scss">
.youtube {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &.disabled {
    opacity: 0.75;
  }

  &__player {
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
      max-width: 380px;
      max-height: 237px;
    }
  }
}
</style>
